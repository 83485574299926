import { FilterOptionValue } from "@elixir/fx";
import React from "react";
import {ChangeAssessmentClient} from "../ApiClients/ApiClient";

type ExceptionPageState = {
    filters: FilterOptionValue[];
};

type Configuration = {
    excludedServices: string[];
}

const initialExceptionPageState: ExceptionPageState = {
    filters: [],
};

const initialConfigration: Configuration = {
    excludedServices: [],
}

type GlobalState = {
    exceptionsPage: ExceptionPageState;
    configuration: Configuration;
};

const initialGlobalState: GlobalState = {
    exceptionsPage: initialExceptionPageState,
    configuration: initialConfigration
};

export enum GlobalActionTypes {
    SET_EXCEPTION_FILTERS = "SET_EXCEPTION_FILTERS",
    INITIAL_LOAD="INITIAL_LOAD"
}

type GlobalActions = { type: GlobalActionTypes.SET_EXCEPTION_FILTERS; payload: FilterOptionValue[] }
    | { type: GlobalActionTypes.INITIAL_LOAD; excludedServices: string[] };

const mainReducer = (state: GlobalState, action: GlobalActions) => {
    switch (action.type) {
        case GlobalActionTypes.SET_EXCEPTION_FILTERS:
            return {
                ...state,
                exceptionsPage: {
                    filters: action.payload,
                },
            };
        case GlobalActionTypes.INITIAL_LOAD:
            return {
                ...state,
                configuration: {
                    excludedServices: action.excludedServices
                },
            };
        default:
            throw new Error();
    }
};

export interface IGlobalContext {}

export const GlobalContext = React.createContext<{
    state: GlobalState;
    dispatch: React.Dispatch<GlobalActions>;
}>({
    state: initialGlobalState,
    dispatch: () => null,
});

export const GlobalContextProvider: React.FC = ({ children }) => {
    const [state, dispatch] = React.useReducer(mainReducer, initialGlobalState);
    const changeAssessmentClient = new ChangeAssessmentClient();

    React.useEffect(() => {
        async function fetchExcludedServices() {
            const approvalProviders = await changeAssessmentClient.getApprovalProviders();
            const safeFlyApprovalProviders = approvalProviders?.find((approvalProvider) =>
                approvalProvider.name === "SafeFly" && approvalProvider.isEnabled)?.providerSupportedServices;
            const servicesArr = safeFlyApprovalProviders != undefined ? safeFlyApprovalProviders
                .filter(safeFlyApprovalProvider => safeFlyApprovalProvider.isEnabled)
                .map(safeFlyApprovalProvider => safeFlyApprovalProvider.serviceName) : [];
            dispatch({type: GlobalActionTypes.INITIAL_LOAD, excludedServices: servicesArr});
        };

        fetchExcludedServices();
    }, [])

    return <GlobalContext.Provider value={{ state, dispatch }}>{children}</GlobalContext.Provider>;
};
