import React from "react";

import { ITooltipHostStyles, mergeStyleSets, TooltipHost, TooltipOverflowMode } from "@fluentui/react";

/**
 * Common style for text overflow
 */
export const textOverflowStyles: ITooltipHostStyles = { root: { overflow: "hidden", textOverflow: "ellipsis" } };

export interface ContentWithTooltipProps extends React.PropsWithChildren<unknown> {
    /**
     * the optional content to render when the tooltip is being rendered, otherwise
     * defaults to the children.
     */
    tooltipContent?: JSX.Element;

    /**
     * Only show tooltip if parent DOM element is overflowing
     */
    showOnParentOverflow?: boolean;

    /**
     * Any additional styles to be applied to the tooltip host
     */
    styles?: ITooltipHostStyles;
}

/**
 * Renders the children wrapped with a tooltip
 */
export const ContentWithTooltip: React.FC<ContentWithTooltipProps> = ({
    children,
    tooltipContent,
    showOnParentOverflow,
    styles,
}: ContentWithTooltipProps) => {
    const overflowMode = showOnParentOverflow ? TooltipOverflowMode.Parent : TooltipOverflowMode.Self;
    const mergedStyles = mergeStyleSets(textOverflowStyles, styles);

    return (
        <TooltipHost styles={mergedStyles} overflowMode={overflowMode} content={tooltipContent || (children as JSX.Element)}>
            {children}
        </TooltipHost>
    );
};
