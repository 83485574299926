
export enum ApiHostEnvironment {
  INTEGRATION = "https://changemanagerfrontdoor-a3c6gbhnc7gsdqhw.z01.azurefd.net",
  PPE = "https://afd-api-changeguard-ppe-auc8b0hveudvh8dx.b02.azurefd.net",
  PROD = "https://afd-api-changeguard-prod-cbdzhxeja8b6chcw.b01.azurefd.net",
}

export default class EnvironmentResolver {
  public get apiHost(): ApiHostEnvironment {
      return this._apiHost;
  }

  private URL_TO_ENVIRONMENT_MAP: any = {
      "default": ApiHostEnvironment.INTEGRATION,
      "localhost": ApiHostEnvironment.INTEGRATION,
      "portal.changeguard.fcm.azure.microsoft.com": ApiHostEnvironment.PROD,
      "portal-staging.changeguard.fcm.azure.microsoft.com": ApiHostEnvironment.PROD,
      "portal.changemanager.fcm.azure.microsoft.com": ApiHostEnvironment.INTEGRATION,
      "portal-staging.changemanager.fcm.azure.microsoft.com": ApiHostEnvironment.INTEGRATION,
      "portal.changeguard.fcmppe.azure.microsoft.com": ApiHostEnvironment.PPE,
      "portal-staging.changeguard.fcmppe.azure.microsoft.com": ApiHostEnvironment.PPE,
      //Adding current Web APP Mappings for INT and PPE
      "changemanageruiwestus2.azurewebsites.net": ApiHostEnvironment.INTEGRATION,
      "ppechangeguarduiwestus2.azurewebsites.net": ApiHostEnvironment.PPE,
      "ppe.portal.changeguard.fcm.azure.microsoft.com": ApiHostEnvironment.PPE,
      "ppe.portal-staging.changeguard.fcm.azure.microsoft.com": ApiHostEnvironment.PPE,
      "afd-ui-changemanager-int-dec6auffcuf2bmg4.b01.azurefd.net": ApiHostEnvironment.INTEGRATION,
      "afd-ui-changeguard-ppe-fkhmgmcvadcrbhdk.b02.azurefd.net": ApiHostEnvironment.PPE,
      "afd-ui-changeguard-prod-edcfa6dncxaagraz.b01.azurefd.net": ApiHostEnvironment.PROD
  };

  private _apiHost: ApiHostEnvironment;

  constructor() {
      this._apiHost = this.getApiHostByURL();
  }

  private getApiHostByURL(): ApiHostEnvironment {
      const host = window.location.hostname;
      const environment = this.URL_TO_ENVIRONMENT_MAP[host];
      return environment ? environment : this.URL_TO_ENVIRONMENT_MAP.default;
  }
}