export enum Status {
    Declined = "Declined",
    PartiallyDeclined = "PartiallyDeclined",
    Approved = "Approved",
    PendingSubmission = "PendingSubmission",
    PendingApproval = "PendingApproval",
    Delegated = "Delegated",
}

export enum DeploymentSystem {
    Ev2 = "Ev2",
}

export enum HttpStatusCode {
    OK = 200,
}

export const STATUS_MAP = {
    [Status.Declined]: "Rejected",
    [Status.PartiallyDeclined]: "Partially Declined",
    [Status.Approved]: "Approved",
    [Status.PendingSubmission]: "Pending Submission",
    [Status.PendingApproval]: "Pending Approval",
    [Status.Delegated]: "Delegated",
};

export type IdNamePair = {
    id: string;
    name?: string;
};

export type NameEmailPair = {
    name?: string;
    email?: string;
};

export type IApproverInfo = {
    approverId?: string;
    approverName?: string;
    approverEmail?: string;
};

export type IApproverNotes = {
    approverEmail: string;
    notes?: string;
};

export type IStatusChange = {
    status: number;
    changeDate: string; //String date
};

export type ISubscription = {
    subscriptionId: string;
    subscriptionName?: string;
    regions?: string[];
};

export type IService = {
    serviceId: string;
    serviceName?: string;
    subscriptions?: ISubscription[];
};

export type ExceptionRequestBody = {
    title?: string;
    requestorEmail?: string;
    requestorName?: string;
    businessJustification?: string;
    exceptionBeginsOn: string; //date-time
    exceptionEndsOn: string; //date-time
    services?: IService[];
    approvers?: IApproverInfo[];
    eventId: string;
    status: Status;

    event?: IEvent;
};

export type IExceptionRequest = {
    exceptionRequestId: string;
    requestDate: string; //date-time
    requestorName?: string;
    requestorEmail?: string;
    title?: string;
    businessJustification?: string;
    exceptionBeginsOn: string; //date-time
    exceptionEndsOn: string; //date-time
    deploymentOfOrigin?: string;
    services?: IService[];
    status: Status;
    approvers?: IApproverInfo[];
    formerApproverIds?: string[];
    changeStatus?: IStatusChange[];
    approversNotes?: IApproverNotes[];
    eventIds?: string[];
    approvedBy?: string;
    followsSDP?: string;
    riskAssessment?: string;
    deploymentSystem?: string;

    event?: IEvent; //!! Not in PROD Contract
};

export type IExceptionRequestTableRow = {
    title?: string;
    exceptionRequestId: string;
    requestDate: string;
    serviceName?: string;
    status: string;
    requestorEmail?: string;
    deploymentOfOrigin?: string;
    approverEmails?: string;
    approvedBy?: string;
}

export type IEventsQuery = {
    startDate: string; //Date-time string
    endDate: string; //Date-time string
    regions?: string[];
};

export type IRegion = {
    id: string;
    name?: string;
};

export type IEvent = {
    id: string;
    name?: string;
    startDate: string; //Date-time string
    endDate: string; //Date-time string
    regions?: IRegion[];
};

export type IEventsInRegion = {
    startDate: string;
    endDate: string;
    region?: IRegion;
    events?: IEvent[];
};

export type DeploymentRiskEstimation = {
    deploymentRiskEstimationId: string;
    requestorEmail: string;
    deploymentId: string;
    deploymentEstimatedStart: string;
    deploymentEstimatedEnd: string;
    exceptionRequestUrl?: string;
};

export type ApprovalProvider = {
    id: string,
    name: string,
    isEnabled: boolean,
    providerSupportedServices: ProviderSupportedService[]
};

export type ProviderSupportedService = {
    id: string,
    serviceId: string,
    serviceName: string,
    isEnabled: boolean,
};

export type ActionMap<M extends { [index: string]: any }> = {
    [Key in keyof M]: M[Key] extends undefined
        ? {
              type: Key;
          }
        : {
              type: Key;
              payload: M[Key];
          };
};

export type FormField = {
    name: string;
    label?: string;
    value?: any;
    error?: string;
};