import React from "react";
import { IExceptionRequest } from "../Types";

type ExceptionFormState = {
    exceptionRequest: IExceptionRequest | null;
    isApprover: boolean;
    isRequestor: boolean;
};

const initialFormState: ExceptionFormState = {
    exceptionRequest: null,
    isApprover: false,
    isRequestor: false,
};

export enum FormActionTypes {
    SET_FIELD = "SET_FIELD",
    SET_FIELDS = "SET_FIELDS",
}

export enum ExceptionFormFields {
    Title = "title",
    Justification = "justification",
    Reason = "reason",
}

export enum ExceptionFormActionTypes {
    UPDATE_REQUEST = "UPDATE_REQUEST",
    SET_OWNERSHIP = "SET_OWNERSHIP",
}

type ExceptionFormActions =
    | { type: ExceptionFormActionTypes.UPDATE_REQUEST; payload: IExceptionRequest }
    | { type: ExceptionFormActionTypes.SET_OWNERSHIP; payload: { isApprover: boolean; isRequestor: boolean } };

const mainReducer = (state: ExceptionFormState, action: ExceptionFormActions) => {
    switch (action.type) {
        case ExceptionFormActionTypes.UPDATE_REQUEST:
            return { ...state, exceptionRequest: action.payload };
        case ExceptionFormActionTypes.SET_OWNERSHIP:
            return { ...state, isApprover: action.payload.isApprover, isRequestor: action.payload.isRequestor };
        default:
            throw new Error();
    }
};

export const ExceptionFormContext = React.createContext<{
    state: ExceptionFormState;
    dispatch: React.Dispatch<ExceptionFormActions>;
}>({
    state: initialFormState,
    dispatch: () => null,
});

export const ExceptionFormContextProvider: React.FC = ({ children }) => {
    const [state, dispatch] = React.useReducer(mainReducer, initialFormState);

    return <ExceptionFormContext.Provider value={{ state, dispatch }}>{children}</ExceptionFormContext.Provider>;
};
