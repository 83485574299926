import React from "react";
import { MessageBarType, PanelType } from "office-ui-fabric-react/lib/index";
import { IMessage } from "@elixir/fx";

type GlobalPanelState = {
    showPanel: boolean;
    panelSize: PanelType;
    fillBackground: boolean;
    isLoading: boolean;
    header: string;
    message?: IMessage;
};

const initialGlobalPanelState: GlobalPanelState = {
    showPanel: false,
    panelSize: PanelType.large,
    fillBackground: false,
    isLoading: false,
    header: ""
};

export enum GlobalPanelActionTypes {
    SET_SHOW_PANEL = "SET_SHOW_PANEL",
    SET_IS_LOADING = "SET_IS_LOADING",
    SET_HEADER = "SET_HEADER",
    SET_PANEL_SIZE = "SET_PANEL_SIZE",
    SHOW_WARNING = "SHOW_WARNING",
    SHOW_ERROR = "SHOW_ERROR",
    CLEAR_ERROR = "CLEAR_ERROR"
}

export enum GlobalPanelHeaders {
    NEW_EXCEPTION = "New Exception Request",
    EXCEPTION_DETAILS= "Exception Request Details"
}

type GlobalPanelActions =
    | { type: GlobalPanelActionTypes.SET_SHOW_PANEL; showPanel: boolean }
    | { type: GlobalPanelActionTypes.SET_IS_LOADING; isLoading: boolean }
    | { type: GlobalPanelActionTypes.SET_HEADER; payload: string }
    | { type: GlobalPanelActionTypes.SET_PANEL_SIZE; payload: PanelType }
    | { type: GlobalPanelActionTypes.SHOW_WARNING; payload: string }
    | { type: GlobalPanelActionTypes.SHOW_ERROR; payload: string }
    | { type: GlobalPanelActionTypes.CLEAR_ERROR }

const mainReducer = (state: GlobalPanelState, action: GlobalPanelActions) => {
    switch (action.type) {
        case GlobalPanelActionTypes.SHOW_ERROR:
            return {
                ...state,
                message: {
                    message: action.payload,
                    type: MessageBarType.error,
                },
            };
        case GlobalPanelActionTypes.SHOW_WARNING:
            return {
                ...state,
                message: {
                    message: action.payload,
                    type: MessageBarType.severeWarning,
                },
            };
        case GlobalPanelActionTypes.CLEAR_ERROR:
            return {
                ...state,
                message: undefined
            };
        case GlobalPanelActionTypes.SET_SHOW_PANEL:
            return { ...state, showPanel: action.showPanel };
        case GlobalPanelActionTypes.SET_IS_LOADING:
            return { ...state, isLoading: action.isLoading };
        case GlobalPanelActionTypes.SET_HEADER:
            return { ...state, header: action.payload };
        case GlobalPanelActionTypes.SET_PANEL_SIZE:
            return { ...state, panelSize: action.payload };
        default:
            throw new Error();
    }
};

export const GlobalPanelContext = React.createContext<{
    state: GlobalPanelState;
    dispatch: React.Dispatch<GlobalPanelActions>;
}>({
    state: initialGlobalPanelState,
    dispatch: () => null,
});

export const GlobalPanelContextProvider: React.FC = ({ children }) => {
    const [state, dispatch] = React.useReducer(mainReducer, initialGlobalPanelState);

    return <GlobalPanelContext.Provider value={{ state, dispatch }}>{children}</GlobalPanelContext.Provider>;
};
