import React from "react";
import {
    IDropdownProps,
    ITextFieldProps,
    Stack,
    ISelectableOption,
    Label,
    List,
    IListProps,
    DefaultEffects,
    mergeStyleSets,
    FocusZone,
    FocusZoneDirection,
    Spinner,
    SpinnerSize,
    Text,
} from "office-ui-fabric-react/lib/index";
import {
    ElxTextField,
    ElxDropdown,
    ElxPrimaryButton,
    getCurrentUser,
    InputMessageTypes,
    IElxDropdownProps,
    IElxTextFieldProps,
    ElxTagContainer,
    TagContainerOrientation,
    TagSeverity,
    ElxPicker,
    IElxPickerProps,
    IPickerItem,
    ElxDialog,
    IElxDialogProps,
    uxSendNotificationAction,
    ElxLink,
    ElxWorkflowContextProvider,
    getLogger,
    ITagData
} from "@elixir/fx";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { GlobalPanelContext, GlobalPanelActionTypes } from "../contexts/GlobalPanelContext";
import {
    NewExceptionFormContext,
    NewExceptionFormActionTypes,
    NewExceptionFormFields,
    NewExceptionFormState,
} from "../contexts/NewExceptionFormContext";
import { ExceptionRequestClient, ServicesInformationClient, EventsRetrievalClient } from "../ApiClients/ApiClient";
import { IService, ISubscription, IEvent, ExceptionRequestBody, Status, IdNamePair, DeploymentSystem, HttpStatusCode } from "../Types";
import { dateFormatter, isEmpty, isTooLong } from "../Utils";
import { DateRangeDisplay, ServiceDisplay } from "../Components";
import { useIsMount } from "../Hooks";
import { GlobalContext } from "../contexts/GlobalContext";

const classNames = mergeStyleSets({
    serviceListItem: {
        padding: "5px",
        selectors: {
            ":hover": {
                background: "#eee",
                cursor: "pointer",
            },
        },
    },
});

const FORM_MESSAGES = {
    NO_EVENTS: {
        key: 0,
        text: "No Upcoming CCOA Events!",
        severity: TagSeverity.Warning
    } as ITagData,
    EXCEPTION_EXISTS: {
        key: 1,
        text: "One or more exceptions already exist for the given list of subscriptions.",
        severity: TagSeverity.Warning
    } as ITagData
};

export const NewExceptionForm: React.FC<{ exitUrl: string }> = ({ exitUrl }) => {
    const servicesInformationClient = new ServicesInformationClient();
    const eventsRetrievalClient = new EventsRetrievalClient();
    const { state, dispatch } = React.useContext(NewExceptionFormContext);
    const panelContext = React.useContext(GlobalPanelContext);
    const globalContext = React.useContext(GlobalContext);
    const currentUser = getCurrentUser();
    const history = useHistory();
    const isMount = useIsMount();
    const globalDispatch = useDispatch();
    const serviceSearchRef = React.useRef<HTMLDivElement>(null!);

    const [titleTextField, setTitleTextField] = React.useState<IElxTextFieldProps>({});
    const [justificationTextField, setJustificationTextField] = React.useState<IElxTextFieldProps>({});
    const [subscriptionPicker, setSubscriptionPicker] = React.useState<IElxPickerProps<ISubscription>>({
        items: [],
        disabled: true,
        selectedKeys: [],
    });
    const [eventDropdown, setEventDropdown] = React.useState<IElxDropdownProps>({ options: [] });
    const [serviceSearchTextbox, setServiceSearchTextbox] = React.useState<IElxTextFieldProps>({ value: "" });
    const [serviceSearchTimeout, setServiceSearchTimeout] = React.useState(0);
    const [servicesList, setServicesList] = React.useState<IListProps<IdNamePair>>({
        items: [],
        onRenderCell: (item, index) => {
            return (
                <div data-is-focusable onClick={() => onServiceItemClick(item)} className={classNames.serviceListItem}>
                    {item?.name}
                </div>
            );
        },
        style: {},
    });
    const [servicesDropdown, setServicesDropdown] = React.useState<{ hidden?: Boolean; loading?: Boolean }>({ hidden: true, loading: false });
    const [confirmationDialog, setConfirmationDialog] = React.useState<IElxDialogProps>({
        hidden: true,
        dismissable: true,
        header: "Confirm Submit",
        primaryButtonText: "Submit",
        cancelButtonText: "Cancel",
    });
    const [formMessages, setFormMessages] = React.useState<ITagData[]>([]);


    const initializeData = async () => {
        panelContext.dispatch({ type: GlobalPanelActionTypes.SET_IS_LOADING, isLoading: true });
        try {
            const events = await eventsRetrievalClient.getEvents(state.startDate!, state.endDate!);
            if (events && events.length) {
                // sort events in ascending order so that the nearer event would be more top on the list.
                const eventsSorted = events.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());
                setEventDropdown({
                    options: eventsSorted
                        .map((event, i) => {
                            return {
                                key: i,
                                text: event.name || "Unknown Event",
                                data: event,
                            };
                        }),
                    selectedKey: 0,
                });
                dispatch({
                    type: NewExceptionFormActionTypes.SET_FIELD,
                    key: NewExceptionFormFields.Event,
                    payload: eventsSorted[0],
                });
            } else {
                //Show message if there are no upcoming Hi-Pri Events
                addFormMessage(FORM_MESSAGES.NO_EVENTS);
            }
        } catch (e) {
            getLogger().error(e);
        }
        panelContext.dispatch({ type: GlobalPanelActionTypes.SET_IS_LOADING, isLoading: false });
    };

    const validateInputs = (currentFormState?: NewExceptionFormState) => {
        if (isMount) return;
        const fields = Object.values(NewExceptionFormFields);
        var inputsValid = true;

        fields.map((field) => {
            if (field === NewExceptionFormFields.Title && (!currentFormState || typeof currentFormState.title !== "undefined")) {
                setTitleTextField({});
                const title = typeof currentFormState?.title === "undefined" ? state.title : currentFormState.title;
                if (isEmpty(title)) {
                    setTitleTextField({ message: { content: "Title can not be empty.", type: InputMessageTypes.Error } });
                    inputsValid = false;
                }
                if (isTooLong(title)) {
                    setTitleTextField({ message: { content: "Title must be less than 512 characters.", type: InputMessageTypes.Error } });
                    inputsValid = false;
                }
            }

            if (field === NewExceptionFormFields.Justification && (!currentFormState || typeof currentFormState.justification !== "undefined")) {
                setJustificationTextField({});
                const justification = typeof currentFormState?.justification === "undefined" ? state.justification : currentFormState.justification;
                if (isEmpty(justification)) {
                    setJustificationTextField({ message: { content: "Justification can not be empty.", type: InputMessageTypes.Error } });
                    inputsValid = false;
                }
                if (isTooLong(justification)) {
                    setJustificationTextField({
                        message: { content: "Justification must be less than 512 characters.", type: InputMessageTypes.Error },
                    });
                    inputsValid = false;
                }
            }

            if (field === NewExceptionFormFields.Service && (!currentFormState || typeof currentFormState.service !== "undefined")) {
                setServiceSearchTextbox({ ...serviceSearchTextbox, message: undefined });
                const service = typeof currentFormState?.service === "undefined" ? state.service : currentFormState.service;
                if (!service) {
                    setServiceSearchTextbox({
                        ...serviceSearchTextbox,
                        message: { content: "Must select a service.", type: InputMessageTypes.Error },
                    });
                    inputsValid = false;
                }
            }

            if (field === NewExceptionFormFields.Subscriptions && (!currentFormState || typeof currentFormState.subscriptions !== "undefined")) {
                setSubscriptionPicker({ ...subscriptionPicker, message: undefined });
                const subscriptions = typeof currentFormState?.subscriptions === "undefined" ? state.subscriptions : currentFormState.subscriptions;
                if (!subscriptions || !subscriptions.length) {
                    setSubscriptionPicker({
                        ...subscriptionPicker,
                        message: { content: "Must select a subscription.", type: InputMessageTypes.Error },
                    });
                    inputsValid = false;
                }
            }
        });

        return inputsValid;
    };

    const updateSubscriptions = (keys: string[]) => {
        const subscriptions = subscriptionPicker.items
            .filter((item) => {
                return keys.includes(item.key);
            })
            .map((item) => item.data!);
        dispatch({
            type: NewExceptionFormActionTypes.SET_FIELD,
            key: NewExceptionFormFields.Subscriptions,
            payload: subscriptions,
        });
        validateInputs({ subscriptions });
    };

    React.useEffect(() => {
        // add when mounted
        document.addEventListener("mousedown", handleDocumentClick);
        initializeData();

        return () => {
            document.removeEventListener("mousedown", handleDocumentClick);
        };
    }, []);

    React.useEffect(() => {
        updateSubscriptions(subscriptionPicker.selectedKeys!);
    }, [subscriptionPicker.selectedKeys]);
    

    const handleDocumentClick = (e: MouseEvent) => {
        if (serviceSearchRef.current.contains(e.target as HTMLElement)) {
            // inside click
            return;
        }

        // outside click
        setServicesDropdown({ hidden: true });
    };

    const onTitleChange: ITextFieldProps["onChange"] = (event, newTitle) => {
        dispatch({
            type: NewExceptionFormActionTypes.SET_FIELD,
            key: NewExceptionFormFields.Title,
            payload: newTitle,
        });
        if (newTitle) validateInputs({ title: newTitle });
    };

    const onJustificationChange: ITextFieldProps["onChange"] = (event, newJustification) => {
        dispatch({
            type: NewExceptionFormActionTypes.SET_FIELD,
            key: NewExceptionFormFields.Justification,
            payload: newJustification,
        });
        if (newJustification) validateInputs({ justification: newJustification });
    };

    const onEventKeyChange: IDropdownProps["onChange"] = (event, option) => {
        if (option) {
            setEventDropdown({
                ...eventDropdown,
                selectedKey: Number(option.key),
            });
            dispatch({
                type: NewExceptionFormActionTypes.SET_FIELD,
                key: NewExceptionFormFields.Event,
                payload: option.data,
            });
        }
    };

    const checkInputs = () => {
        if (!validateInputs()) return;
        else setConfirmationDialog({ ...confirmationDialog, hidden: false });
    };

    const submitNewExceptionForm = async () => {
        setConfirmationDialog({ ...confirmationDialog, hidden: true });
        const exceptionRequestClient = new ExceptionRequestClient();

        const exceptionRequestBody: ExceptionRequestBody = {
            title: state.title,
            requestorEmail: currentUser.account,
            requestorName: currentUser.name,
            approvers: state.approvers,
            businessJustification: state.justification,
            exceptionBeginsOn: state.event!.startDate,
            exceptionEndsOn: state.event!.endDate,
            services: [{ ...state.service!, subscriptions: state.subscriptions }],
            eventId: state.event!.id,
            status: Status.PendingApproval,
        };

        //panel loading.
        panelContext.dispatch({ type: GlobalPanelActionTypes.SET_IS_LOADING, isLoading: true });
        try {
            const newExceptionResponse = await exceptionRequestClient.createNewExceptionRequest(exceptionRequestBody);
            if (newExceptionResponse.status === HttpStatusCode.OK) {
                // no exception item was created, display warning message
                addFormMessage(FORM_MESSAGES.EXCEPTION_EXISTS);
            } else {
                //close new exception panel & do a toast
                history.push(exitUrl);
                globalDispatch(
                    uxSendNotificationAction({
                        message: `Exception Request with Id: ${newExceptionResponse.data.exceptionRequestId} has been created`
                    })
                );
            }
        } catch (e) {
            //if unsuccessful
            // display error message  at the top of panel.
            getLogger().error(e);
        }
        panelContext.dispatch({ type: GlobalPanelActionTypes.SET_IS_LOADING, isLoading: false });
    };

    const onRenderEventOption = (
        option?: ISelectableOption,
        defaultRender?: (option?: ISelectableOption) => JSX.Element | null
    ): JSX.Element | null => {
        let event: IEvent = option!.data;
        return (
            <Stack>
                <span>{event.name}</span>
                <DateRangeDisplay
                    startDate={dateFormatter.format(new Date(event.startDate))}
                    endDate={dateFormatter.format(new Date(event.endDate))}
                />
            </Stack>
        );
    };

    const onServiceItemClick = async (serviceItem?: IdNamePair) => {
        if (serviceItem) {
            setServicesDropdown({ hidden: true });
            const service = { serviceId: serviceItem.id, serviceName: serviceItem.name } as IService;
            //set ServiceKey
            dispatch({
                type: NewExceptionFormActionTypes.SET_FIELD,
                key: NewExceptionFormFields.Service,
                payload: service,
            });
            validateInputs({ service });

            try {
                //update approvers for this service
                const approvers = await servicesInformationClient.getServiceApprovers(serviceItem.id);
                dispatch({
                    type: NewExceptionFormActionTypes.SET_FIELD,
                    key: NewExceptionFormFields.Approvers,
                    payload: approvers.map((approver) => ({ approverName: approver.name, approverEmail: approver.email })),
                });

                const fullServiceInformation = await servicesInformationClient.getService(serviceItem.id);

                //Update subscription drop down options
                const subscriptionData: ISubscription[] = fullServiceInformation?.subscriptions!;
                setSubscriptionPicker({
                    items: subscriptionData
                        .sort((a, b) => a.subscriptionName!.localeCompare(b.subscriptionName!))
                        .map<IPickerItem>((subscription, i) => {
                            return {
                                key: String(i),
                                text: subscription.subscriptionName || "",
                                data: subscription,
                            };
                        }),
                    selectedKeys: [],
                    disabled: false,
                });
                dispatch({
                    type: NewExceptionFormActionTypes.SET_FIELD,
                    key: NewExceptionFormFields.Subscriptions,
                    payload: undefined,
                });
            } catch (e) {
                getLogger().error(e);
            }
        }
    };

    const isServiceExcluded = () => {
        const service = state.service?.serviceName?.trim().replaceAll(' ', '').toLowerCase() ?? "";
        const excludedServices = globalContext.state.configuration.excludedServices || [];
        var excludedServicesTrimmed = excludedServices.map(item => item.replaceAll(' ', '').trim().toLowerCase());
        return excludedServicesTrimmed.includes(service) ? true : false;
    }

    const onServiceSearchChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
        setServiceSearchTextbox({ ...serviceSearchTextbox, value });
        if (!value) setServicesDropdown({ hidden: true });
        clearTimeout(serviceSearchTimeout);

        if (value) {
            setServiceSearchTimeout(
                window.setTimeout(async () => {
                    //Populate new options then open dropdown
                    /**  Ajax Values */
                    setServicesDropdown({ hidden: false, loading: true });
                    try {
                        const services = await servicesInformationClient.getServicesByName(value);
                        if (services && services.length) {
                            setServicesList({ ...servicesList, items: services });
                        } else setServicesList({ ...servicesList, items: [] });
                    } catch (e) {
                        getLogger().error(e);
                    }
                    setServicesDropdown({ hidden: false, loading: false });
                }, 500)
            );
        }
    };

    const onServiceSearchFocus = () => {
        if (serviceSearchTextbox.value) setServicesDropdown({ hidden: false });
    };

    //Will remove canceled form message from the top of the form
    const onRemoveFormMessage = (message: ITagData) => {
        const newMessages = [...formMessages].filter(x => x.key !== message.key);
        setFormMessages(newMessages);
    }

    //Adds form messages to the top of the form if it does not already exist in the form.
    const addFormMessage = (message: ITagData) => {
        const newMessage = formMessages.find(x => x.key === message.key);
        if (newMessage) return;
        setFormMessages([...formMessages, message]);
    }

    return (
        <form>
            <ElxWorkflowContextProvider name="New Exception Workflow">
                <ElxTagContainer displayOrientation={TagContainerOrientation.Vertical} onRemoveTag={onRemoveFormMessage}  data={formMessages}/>
                <ElxDropdown label="Deployment System" required={true} options={[{ key: 0, text: DeploymentSystem.Ev2 }]} selectedKey={0} disabled={true} />
                <br />
                <ElxDropdown
                    label="Event"
                    required={true}
                    options={eventDropdown.options}
                    selectedKey={eventDropdown.selectedKey}
                    disabled={!(eventDropdown.options && eventDropdown.options.length)}
                    onChange={onEventKeyChange}
                    onRenderOption={onRenderEventOption}
                />
                {state.event != null ? (
                    <DateRangeDisplay
                        startDate={dateFormatter.format(new Date(state.event.startDate))}
                        endDate={dateFormatter.format(new Date(state.event.endDate))}
                    />
                ) : null}
                <br />
                <ElxTextField
                    label="Title"
                    placeholder="Title of your exception request"
                    required={true}
                    value={state.title}
                    onChange={onTitleChange}
                    message={titleTextField.message}
                />
                <br />
                <ElxTextField
                    label="Justification"
                    required={true}
                    multiline={true}
                    rows={4}
                    placeholder="Enter reason for neeeding an exception"
                    value={state.justification}
                    onChange={onJustificationChange}
                    message={justificationTextField.message}
                />
                <br />
                <Stack tokens={{ childrenGap: 10 }}>
                    <FocusZone onFocus={onServiceSearchFocus} direction={FocusZoneDirection.vertical}>
                        <div ref={serviceSearchRef}>
                            <ElxTextField
                                label="Service"
                                required={true}
                                placeholder="Search by Service Name"
                                value={serviceSearchTextbox.value}
                                onChange={onServiceSearchChange}
                                message={serviceSearchTextbox.message}
                            />
                            <div style={{ position: "relative" }}>
                                <div
                                    style={{
                                        boxShadow: DefaultEffects.elevation8,
                                        padding: "10px",
                                        position: "absolute",
                                        backgroundColor: "white",
                                        zIndex: 1000,
                                        display: servicesDropdown.hidden ? "none" : "block",
                                    }}
                                >
                                    {servicesDropdown.loading ? (
                                        <Spinner size={SpinnerSize.xSmall} label="Searching..." ariaLive="assertive" labelPosition="right" />
                                    ) : null}
                                    {servicesList.items?.length ? <List {...servicesList} /> : "No results"}
                                </div>
                            </div>
                        </div>
                    </FocusZone>
                    {state.service ? <ServiceDisplay serviceName={state.service.serviceName} /> : null}
                </Stack>
                <br />
                <ElxPicker
                    label="Subscription"
                    required={true}
                    items={subscriptionPicker.items}
                    disabled={subscriptionPicker.disabled}
                    placeHolder={subscriptionPicker.disabled ? "Select a service" : "Select a subscription"}
                    onMultiSelectChange={(keys) => setSubscriptionPicker({ ...subscriptionPicker, selectedKeys: [...keys] })}
                    selectedKeys={subscriptionPicker.selectedKeys}
                    labelActions={[
                        <ElxLink
                            label="Subscription Select All"
                            key={1}
                            disabled={subscriptionPicker.disabled}
                            onClick={() => {
                                const allKeys = subscriptionPicker.items.map((item, i) => String(i));
                                setSubscriptionPicker({ ...subscriptionPicker, selectedKeys: allKeys });
                            }}
                        >
                            Select All
                        </ElxLink>,
                        <ElxLink
                            label="Subscription De-Select All"
                            key={2}
                            disabled={subscriptionPicker.disabled}
                            onClick={() => setSubscriptionPicker({ ...subscriptionPicker, selectedKeys: [] })}
                        >
                            De-Select All
                        </ElxLink>,
                    ]}
                    multiSelect={true}
                    message={subscriptionPicker.message}
                />
                <br />
                <ElxDropdown
                    label="Deployment Location"
                    required={true}
                    options={[{ key: 0, text: "All regions" }]}
                    selectedKey={0}
                    disabled={true}
                />
                <br />
                {state.approvers && state.approvers.length ? (
                    <Stack>
                        <Label>Approvers:</Label>
                        <ElxTagContainer
                            displayOrientation={TagContainerOrientation.Horizontal}
                            data={state.approvers.map((approverInfo, i) => ({
                                key: i,
                                text: approverInfo.approverEmail!,
                                severity: TagSeverity.Default,
                            }))}
                        />
                    </Stack>
                ) : null}
                <br />

                {isServiceExcluded() ?
                    <Text style={{ color: 'white', background: 'red', display: 'block', marginBottom: '1.5rem', fontWeight: '600' }}>
                        Your request indicates that you are enrolled in R2D.&nbsp;
                        Please navigate to the <a target="_blank" style={{ textDecoration: "underline" }} href="https://aka.ms/safefly">R2D</a> form to proceed with a request for completing R2D reviews.
                    </Text> : ""}

                <ElxPrimaryButton disabled={!(eventDropdown.options && eventDropdown.options.length) || isServiceExcluded()} text="Submit" onClick={checkInputs} />
                <ElxDialog
                    {...confirmationDialog}
                    onPrimaryButtonClick={submitNewExceptionForm}
                    onCancelButtonClick={() => setConfirmationDialog({ ...confirmationDialog, hidden: true })}
                >
                    <Text>Are you sure you want to create this Exception Request?</Text>
                </ElxDialog>
            </ElxWorkflowContextProvider>
        </form>
    );
};
