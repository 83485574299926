import { NeutralColors } from '@fluentui/theme';
import { mergeStyleSets } from 'office-ui-fabric-react';

const PanelStyles = mergeStyleSets({
  main: {
    backgroundColor: NeutralColors.white
  },
  header: {
    paddingLeft: "2rem",
    paddingRight: "1rem"
  },
  commands: {
    borderBottom: `1px solid ${NeutralColors.gray20}`,
    marginTop: "0px",
    padding: "1rem"
  },
  content: {
    paddingTop: "1rem"
  }
});

export default PanelStyles;