import React from "react";
import { Stack, FontIcon } from "office-ui-fabric-react/lib";
import { ElxPersona, ElixirPersonaTypes } from "@elixir/fx";

type DateDisplayProps = {
    startDate: string;
    endDate: string;
};
export const DateRangeDisplay = ({ startDate, endDate }: DateDisplayProps) => {
    return (
        <Stack horizontal tokens={{ childrenGap: 10, padding: 10 }}>
            <FontIcon style={{ color: "green" }} iconName="Calendar" />
            <span>
                {startDate} - {endDate}
            </span>
        </Stack>
    );
};

type ServiceDisplayProps = {
    serviceName?: string;
};
export const ServiceDisplay = ({ serviceName }: ServiceDisplayProps) => {
    return (
        <div style={{ background: "#eee", padding: "5px" }}>
            <ElxPersona userPrincipalName={serviceName || ""} displayName={serviceName || ""} type={ElixirPersonaTypes.Name} />
        </div>
    );
};
