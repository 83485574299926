import React from "react";
import { IService, ISubscription, IEvent, IApproverInfo } from "../Types";

export type NewExceptionFormState = {
    event?: IEvent;
    title?: string;
    justification?: string;
    service?: IService;
    subscriptions?: ISubscription[];
    approvers?: IApproverInfo[];
    startDate?: string;
    endDate?: string;
};

const initialFormState: NewExceptionFormState = {
    title: "",
    justification: "",
    startDate: new Date().toISOString(),
    endDate: new Date(new Date().setMonth(new Date().getMonth() + 6)).toISOString(), // set end date to be 6 months from now
};

export enum NewExceptionFormActionTypes {
    SET_FIELD = "SET_FIELD",
    SET_FIELDS = "SET_FIELDS",
}

export enum NewExceptionFormFields {
    Title = "title",
    Justification = "justification",
    Service = "service",
    Subscriptions = "subscriptions",
    Event = "event",
    Approvers = "approvers",
}

type NewExceptionFormActions =
    | { type: NewExceptionFormActionTypes.SET_FIELD; key: NewExceptionFormFields; payload: any }
    | { type: NewExceptionFormActionTypes.SET_FIELDS; keys: NewExceptionFormFields[]; payloads: any[] };

const mainReducer = (state: NewExceptionFormState, action: NewExceptionFormActions) => {
    switch (action.type) {
        case NewExceptionFormActionTypes.SET_FIELD:
            return { ...state, [action.key]: action.payload };
        case NewExceptionFormActionTypes.SET_FIELDS:
            const newStates = {};
            action.keys.map((key, i) => {
                Object.assign(newStates, { [key]: action.payloads[i] });
            });
            return { ...state, ...newStates };
        default:
            throw new Error();
    }
};

export const NewExceptionFormContext = React.createContext<{
    state: NewExceptionFormState;
    dispatch: React.Dispatch<NewExceptionFormActions>;
}>({
    state: initialFormState,
    dispatch: () => null,
});

export const NewExceptionFormContextProvider: React.FC = ({ children }) => {
    const [state, dispatch] = React.useReducer(mainReducer, initialFormState);

    return <NewExceptionFormContext.Provider value={{ state, dispatch }}>{children}</NewExceptionFormContext.Provider>;
};
