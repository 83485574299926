import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
import { setConfig, LoginType, ElxShell, IModule, ElxActionButton } from "@elixir/fx";
import { App } from "./App";
import { GlobalPanelContextProvider } from "./contexts/GlobalPanelContext";
import { GlobalContextProvider } from "./contexts/GlobalContext";
import registerServiceWorker from "./registerServiceWorker";

const rootElement = document.getElementById("root");
const isDevelopment = process.env.NODE_ENV === "development";

setConfig({
    authentication: "aad",
    clientId: process.env.REACT_APP_CLIENT_ID,
    tenantId: process.env.REACT_APP_TENANT_ID,
    loginType: LoginType.Redirect,
    tokenCacheLocation: "localStorage",
    tokenRefreshUrl: "/auth.html",
    baseUrl: `https://${window.location.host}`,
    scopes: [`${process.env.REACT_APP_CHANGEGUARD_API_SCOPE}`],
    graphScopes: ["User.Read"],
    applicationTitle: process.env.REACT_APP_TITLE,
    debug: isDevelopment,
    telemetry: {
        settings: {
            config: {
                instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY,
            },
        },

        /**
         * (optional) this enables the interaction tracking for ElixirFx components
         */
        trackInteraction: true,

        /**
         * (optional) if you are also using AAD auth thru ElixirFx, this enables
         *            authenticated user tracking, user alias will be logged.
         *            by default, this is disabled.
         */
        trackAuthenticatedId: true,
    },
});

const modules: IModule[] = [
    {
        name: "home",
        env: "development",
        route: {
            index: "home",
            name: "Home",
            iconProps: {
                iconName: "Home",
            },
            path: "/home",
            isRouteable: true,
            inSidebar: true,
            component: App,
        },
    },
];

ReactDOM.render(
    <GlobalContextProvider>
        <GlobalPanelContextProvider>
            <ElxShell modules={modules} defaultPath="./home" defaultSidebarCollapsed={true}>
                <ElxShell.Branding iconName="Shield" applicationName={process.env.REACT_APP_NAME} />
                <ElxShell.Actions
                    actions={[
                        {
                            name: 'Help',
                            iconProps: { iconName: 'Unknown' },
                            buttonProps: {
                              menuProps: {
                                items: [
                                  {
                                    key: '1',
                                    text: 'Change Guard Support',
                                    title: 'Change Guard Support',
                                    target: '_blank',
                                    href: 'mailto:chgguardsupport@microsoft.com',
                                  },
                                  {
                                    key: '2',
                                    text: 'Change Guard User Guide',
                                    title: 'Change Guard User Guide',
                                    target: '_blank',
                                    href: 'https://aka.ms/ChangeGuardUserGuide',
                                  },
                                ],
                              },
                            },
                          },
                        {
                            name: "Data Privacy Notice",
                            iconProps: { iconName: "RedEye" },
                            buttonProps: {
                                href: "https://msdpn.azurewebsites.net/default?LID=62",
                                target: "_blank",
                            },
                        },
                        {
                            name: "Report an Issue",
                            iconProps: { iconName: "IncidentTriangle" },
                            buttonProps: {
                                href: "https://aka.ms/changeguardincident",
                                target: "_blank",
                            },
                        },
                    ]}
                />
            </ElxShell>
        </GlobalPanelContextProvider>
    </GlobalContextProvider>,
    rootElement
);

registerServiceWorker();
